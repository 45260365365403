:root {
  /* РАЗМЕРЫ */

  /* Отступы */
  --indent-xs: 4px;
  --indent-s: 8px;
  --indent-m: 16px;
  --indent-l: 24px;
  --indent-xl: 36px;
  --indent-xxl: 48px;

  /* Размеры иконок */
  --icon-size-s: 18px;
  --icon-size-m: 24px;
  --icon-size-l: 36px;
  --icon-size-xl: 48px;

  /* ------------------------- */
  /* Глобальный цвет фона  */
  --bg-color: #ececec;

  /* ------------------------- */
  /* Тени */
  --shadow-modal: 15px 15px 15px 5px #717070;
  --shadow-block: 5px 5px 10px 3px #ccc;
  --shadow-elem: 0 5px 5px rgba(221, 221, 221, 1);
  --shadow-item: 5px 5px 5px rgba(221, 221, 221, 1);

  --shadow-bage: 5px 5px 5px 0px rgba(52, 52, 52, 0.3);
}

/* sm */
@media (min-width: 600px) {
  :root {
  }
}

/* md */
@media (min-width: 960px) {
  :root {
  }
}

/* lg */
@media (min-width: 1280px) {
  :root {
  }
}

/* xl */
@media (min-width: 1920px) {
  :root {
  }
}
