:root {
  /* Основной цвет */
  --primary-font-color: #000;
  --primary-font-size: 16px;

  /* Второстепенный */
  --secondary-font-color: #5e5e5e;
  --secondary-font-size: 15px;

  /* Дополнительный */
  --additional-font-color: #5e5e5e;
  --additional-font-size: 14px;

  /* Заголовки */
  --h1-text-size: 48px;
  --h2-text-size: 36px;
  --h3-text-size: 28px;
  --h4-text-size: 24px;
  --h5-text-size: 18px;
  --h6-text-size: 16px;
}

/* Заголовки */
.f-h1,
.f-h2,
.f-h3,
.f-h4,
.f-h5,
.f-h6 {
  color: #282c34;
  font-weight: 800;
}

.f-h1 {
  font-size: var(--h1-text-size);
}

.f-h2 {
  font-size: var(--h2-text-size);
}

.f-h3 {
  font-size: var(--h3-text-size);
}

.f-h4 {
  font-size: var(--h5-text-size);
}

.f-h5 {
  font-size: var(--h5-text-size);
}

.f-h6 {
  font-size: var(--h6-text-size);
}

/* Пункт меню в быстрых контактах (например "Написать в whatsapp") */
.f-quick-contacts {
  font-size: 20px;
  font-weight: bold;
}

/* Основной текст */
.f-main-regular {
  font-size: 16px;
  color: #282c34;
  font-weight: 500;
}

/* Второстепенный текст */
.f-secondary-regular {
  font-size: 15px;
  color: #282c34;
}

/* Второстепенный текст, например описание вакансии */
.f-secondary-pale {
  font-size: 15px;
  color: #5e5e5e;
}

/* Второстепенный текст, например описание вакансии */
.f-banner-1 {
  font-size: 18px;
  font-weight: bold;
}

/* Второстепенный текст, например описание вакансии */
.f-footer-link {
  font-size: 15px;
  font-weight: bold;
}

.f-bold {
  font-weight: 600;
}

.f-link {
  font-weight: 500;
  color: blue;
}

/* sm */
@media (min-width: 600px) {
}

/* md */
@media (min-width: 960px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1920px) {
}
