.ic {
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.small {
  width: var(--icon-size-s);
  height: var(--icon-size-s);
}

.medium {
  width: var(--icon-size-m);
  height: var(--icon-size-m);
}

.large {
  width: var(--icon-size-l);
  height: var(--icon-size-l);
}

.xl {
  width: var(--icon-size-xl);
  height: var(--icon-size-xl);
}

/* sm */
@media (min-width: 480px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px) {
}

/* xl */
@media (min-width: 1200px) {
}
