.modal {
  background: none;
}

.overlay {
  background-color: #999;
  opacity: 0.9;
  animation-duration: 300ms;
  animation-name: appearance;
}

.content {
  position: relative;
  pointer-events: auto;
  box-shadow: var(--shadow-modal);
}

@keyframes appearance {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.8;
  }
}
