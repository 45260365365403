.footer {
}

.nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.col {
  margin: 0 var(--indent-xs);
}

.item {
  margin: var(--indent-s) 0;
  color: #929292;
  font-size: 14px;
  font-weight: 700;
}

.ownership {
  margin-top: var(--indent-m);
  color: #929292;
  font-size: 14px;
  font-weight: 500;
}


@media (min-width: 400px) {
  .col {
    margin: 0 var(--indent-s);
  }

  .item {
    font-size: 15px;
  }
}


/* sm */
@media (min-width: 480px) {
  .nav {
    justify-content: center;
  }
  .col {
    margin: 0 var(--indent-m);
  }

  .item {
    font-size: 18px;
  }
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px) {
}

/* xl */
@media (min-width: 1200px) {
}
