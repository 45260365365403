.txt {
}

.paragraph {
  line-height: 24px;
  font-weight: 600;
  font-size: 18px;
}

.textList {
  line-height: 24px;
  font-weight: 700;
  font-size: 17px;
}

.info {
  line-height: 28px;
  font-weight: 400;
  font-size: 18px;
  text-align: justify;
}

.paragraph + .paragraph {
  margin-top: var(--i-elem-top);
}

h1 + .paragraph,
h2 + .paragraph,
h3 + .paragraph,
h4 + .paragraph,
h5 + .paragraph,
h6 + .paragraph {
  margin-top: var(--i-elem-top);
}
