.item {
}

.active {
  color: crimson;
}

/* sm */
@media (min-width: 480px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px) {
}

/* xl */
@media (min-width: 1200px) {
}
