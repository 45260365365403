:root {
  /* Отступ слева 'красной строки' */
  --first-line-indent: 20px;
}

.g-splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}

/* Делает два противоположных уголка тенями */
.g-corner2 {
  box-shadow: -20px 20px 0 -17px #eee, 20px -20px 0 -17px #eee, 20px 20px 0 -20px #592385, 0 0 0 2px #592385;
}

/* Для элементов */
.g-first-line-indent {
  margin-left: var(--first-line-indent);
}

.g-link-clear-style {
  color: inherit;
  size: inherit;
}

.g-bg-linear-main {
  background: linear-gradient(.25turn, #CDB5FF, #B8C0FF);
}

.g-bg-linear-primary {
  background: linear-gradient(.25turn, #32CCA6, #80DFC9);
}

.g-bg-linear-secondary {
  background: linear-gradient(.25turn, #F4AE72, #F7CBA4);
}

.g-page-content {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.g-page-content>* {
  margin: auto;
}


/* sm */
@media (min-width: 600px) {
}

/* md */
@media (min-width: 960px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1920px) {
}
