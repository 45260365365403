.modal {
  background-color: #c3cacc;
  padding-bottom: var(--indent-l);
}

.sep {
  border: none;
  color: #000;
  background-color: #000;
  height: 1px;
}

.line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: var(--indent-l) var(--indent-l);
}

.txt {
  margin-left: var(--indent-m);
}

.bottom {
  text-align: center;
  margin-top: calc(var(--indent-xl) * 3);
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--indent-l) var(--indent-l);
}

.cross,
.crossDim {
  width: var(--icon-size-m);
  height: var(--icon-size-m);
}

.title {
  font-size: 24px;
}

