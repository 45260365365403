.table {
}

.table tr td:nth-child(1) {
  /*padding-top: var(--indent-s);*/
}

.table tr td:nth-child(2) {
  padding-left: var(--indent-m);
  text-align: right;
}

.additional {
  color: var(--additional-font-color);
  font-size: var(--additional-font-size);
}

.table tr td {
  padding-top: var(--indent-xs);
}
