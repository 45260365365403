.preview {
  min-width: 100px;
  height: 200px;
  margin: 10px;
  box-shadow: 5px 5px 15px 1px rgba(48, 49, 54, 0.5);
  border-radius: 10px;
  background-color: #cccccc;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
  padding-bottom: var(--indent-s);
  overflow: hidden;
}

.preview:hover .img {
  opacity: 1;
}

.img {
  min-width: 50px;
  min-height: 50px;
  opacity: 0.9;
  transition: opacity 300ms;
  border: 2px gold dot-dash;
  outline: chocolate 2px dot-dot-dash;
  object-fit: cover;
  width: 100%;
}

.model {
  font-size: var(--additional-font-size);
}

.note {
  margin-top: var(--indent-s);
}

.year {
  font-size: var(--additional-font-size);
  color: var(--additional-font-color);
}

/* sm */
@media (min-width: 600px) {
}

/* md */
@media (min-width: 960px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1920px) {
}
