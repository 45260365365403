:root {
  /* Поля страницы */
  --i-padding-side: var(--indent-l);

  /* Вертикальный/боковой отступы между блоками */
  --i-block-top: var(--indent-xxl);
  --i-block-side: var(--indent-xxl);

  /* Вертикальный/боковой отступы между модулями */
  --i-mod-top: var(--indent-l);
  --i-mod-side: var(--indent-l);

  /* Вертикальный/боковой отступы между элементами */
  --i-elem-top: var(--indent-s);
  --i-elem-side: var(--indent-s);
}

/* Верхний отступ между блоками */
.i-block-top {
  margin-top: var(--indent-xxl);
}

/* Верхний отступ между модулями */
.i-mod-top {
  margin-top: var(--indent-xl);
}

/* Верхний отступ между элементами */
.i-elem-top {
  margin-top: var(--indent-m);
}

/* Отступ после постера снизу */
.i-after-poster-bottom {
  margin-bottom: var(--indent-l);
}

/* Поля блока */
.i-page-both {
  padding-left: var(--i-padding-side);
  padding-right: var(--i-padding-side);
}

/* sm */
@media (min-width: 600px) {
}

/* md */
@media (min-width: 960px) {
}

/* lg */
@media (min-width: 1280px) {
}

/* xl */
@media (min-width: 1920px) {
}
