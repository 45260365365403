.contUs {
  display: flex;
  justify-content: center;
  align-items: center;

  /*border: 3px solid gold;*/
  box-shadow: var(--shadow-bage);
  border-radius: 10px;
  background-color: #fff;
  padding: var(--indent-s) var(--indent-m);
  width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.txt {
  width: 100px;
  text-align: center;
}

.img {
  margin-right: var(--i-elem-side);
}
