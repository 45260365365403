.header {
  height: 70px;
  padding: var(--indent-m) var(--indent-m);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 800;
  font-size: calc(100vw / 20);
}

/* sm */
@media (min-width: 480px) {
  .title {
    font-size: 28px;
  }
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 992px) {
}

/* xl */
@media (min-width: 1200px) {
}
