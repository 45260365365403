body {
  background-color: var(--bg-color);
}

#root {
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
}

header {
}

main {
  flex-grow: 1;
}

footer {
  padding: var(--indent-l);
}
